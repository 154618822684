import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

interface ILoaderData {
  title?: string;
  message?: string;
}

interface ILoaderStateContext extends ILoaderData {
  isActive: boolean;
}

interface ILoaderDispatchContext {
  showLoader: (loaderData?: ILoaderData) => void;
  hideLoader: () => void;
}

const LoaderStateContext = createContext<ILoaderStateContext | undefined>(
  undefined,
);

const LoaderDispatchContext = createContext<ILoaderDispatchContext | undefined>(
  undefined,
);

const LoaderProvider: FC<{children: ReactNode}> = ({children}) => {
  const [loaderState, setLoaderState] = useState<ILoaderStateContext>({
    isActive: false,
  });

  const showLoader = useCallback((loaderData?: ILoaderData) => {
    setLoaderState(() => {
      return {
        isActive: true,
        message: loaderData?.message || undefined,
        title: loaderData?.title || undefined,
      };
    });
  }, []);

  const hideLoader = useCallback(() => {
    setLoaderState(() => {
      return {
        isActive: false,
        title: undefined,
        message: undefined,
      };
    });
  }, []);

  return (
    <LoaderStateContext.Provider value={loaderState}>
      <LoaderDispatchContext.Provider value={{showLoader, hideLoader}}>
        {children}
      </LoaderDispatchContext.Provider>
    </LoaderStateContext.Provider>
  );
};

const useLoaderStateContext = (): ILoaderStateContext => {
  const context = useContext(LoaderStateContext);

  if (!context) {
    throw Error('useLoaderStateContext used outside of LoaderStateContext');
  }
  return context;
};

const useLoaderDispatchContext = (): ILoaderDispatchContext => {
  const context = useContext(LoaderDispatchContext);

  if (!context) {
    throw Error(
      'useLoaderDispatchContext used outside of LoaderDispatchContext',
    );
  }
  return context;
};

export {LoaderProvider, useLoaderStateContext, useLoaderDispatchContext};
